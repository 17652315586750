import ArchiveListing from '../../dynamic/archive-listing/archive-listing';
import Pagination from '../../dynamic/pagination/pagination';
import PageFeedbackForm from '../../simple/page-feedback-form/page-feedback-form';
import Tablist from '../../dynamic/tablist/tablist';
import Accordion from '../../dynamic/accordion/accordion';
import TabbedContent from '../../dynamic/tabbed-content/tabbed-content';
import Nav from '../../dynamic/nav/nav';
import Header from '../../section/header/header';
import Footer from '../../section/footer/footer';
import ResponsiveTables from '../../base/02-content/responsiveTable';
import Content from '../../base/02-content/content';

//global scope
//used for multiple instances of the same component
var globalClasses = {};
window.classes = globalClasses;

//used for class to class interactions
var mainClasses = {
  Accordion,
  ArchiveListing,
  Content,
  Footer,
  Header,
  Nav,
  PageFeedbackForm,
  Pagination,
  ResponsiveTables,
  TabbedContent,
  Tablist,
};

//used for class to class interactions
var mainClassesInstances = {};

function init() {
  for (const [key, value] of Object.entries(mainClasses)) {
    mainClassesInstances[key] = new value();
  }
}

document.addEventListener('DOMContentLoaded', init);

export default mainClasses;

import { PAGE_HASH_DATA, scrollToContainer } from '../../assets/js/utility';

class ArchiveListing {
  constructor() {
    this.archiveSection = document.querySelector(`#archiveListing`);

    if (this.archiveSection) {
      this.InitArchiveSection();
    }
  }

  InitArchiveSection() {
    if (
      PAGE_HASH_DATA.windowHashValue != '' &&
      this.archiveSection.id == PAGE_HASH_DATA.windowHashValue
    ) {
      scrollToContainer(
        this.archiveSection.querySelector('ol'),
        '.dynamic-archive-listing'
      );
    }
  }
}

export default ArchiveListing;

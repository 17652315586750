import { addClass, removeClass } from '../../assets/js/utility';

class Pagination {
  constructor() {
    this.paginationElements = document.querySelectorAll('.dynamic-pagination');
    this.breakpoint = 588; // match xs breakpoint in styles
    this.extendedClass = 'extended-clip';

    if (!this.paginationElements || this.paginationElements.length <= 0) {
      return;
    }

    window.addEventListener('resize', () => this.extendNavClipping());
    this.extendNavClipping();
  }

  /**
   * Checks if the window size is small enough the pagination should be
   * further clipped so as to not stretch the page
   */
  extendNavClipping() {
    // check if any change needed
    if (
      (window.innerWidth < this.breakpoint &&
        this.paginationElements[0].classList.contains(this.extendedClass)) ||
      (window.innerWidth >= this.breakpoint &&
        !this.paginationElements[0].classList.contains(this.extendedClass))
    ) {
      return;
    }

    this.paginationElements.forEach((el) => {
      if (window.innerWidth < this.breakpoint) {
        addClass(el, this.extendedClass);
      } else {
        removeClass(el, this.extendedClass);
      }
    });
  }
}

export default Pagination;
